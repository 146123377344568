/* Core */
import { createSlice } from "@reduxjs/toolkit";
import { token } from "./selectors";
import {
  asyncChildAreaWindow,
  clearLoginLocalStorage,
  getLoginLocalStorage,
  setLoginLocalStorage,
} from "@/util/util";
import { loginAsync, loginSendSmsAsync } from "./thunks";
import { Toast } from "fe-react-pop";

const initToken = getLoginLocalStorage();

const initialState: LoginSliceState = {
  token: initToken?.token ?? "",
};

export const loginSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    login: (state) => {
      state.token = "loginToken";
      setLoginLocalStorage({
        token,
      });
    },
    logout: (state) => {
      state.token = "";
      clearLoginLocalStorage();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        // state.status = 'loading';
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        const { payload } = action;
        const { detail } = payload.data;
        if (detail) {
          setLoginLocalStorage(detail);
          state.token = detail.token;
        }
      })
      .addCase(loginAsync.rejected, (state, action) => {
        Toast({
          content: action.error.message ?? "",
        });
      });

    builder.addCase(loginSendSmsAsync.rejected, (state, action) => {
      Toast({
        content: action.error.message ?? "",
      });
    });
  },
});

/* Types */
export interface LoginSliceState {
  token: string;
}

