import { AxiosResponse } from "axios";
import { t } from "..";

export interface IEditUserInfoParams {
    userName: string;
    userSex: number;
    userBirth: string;
    placeWork: string;
  
  }
  interface IEditUserInfoRes {
  }
  export const editUserInfo = (data: IEditUserInfoParams) => {
    return t.request<IEditUserInfoParams, AxiosResponse<IEditUserInfoRes>>({
      url: "/api/user_api/editUserInfo",
      method: "post",
      data,
    });
  };

  interface IGetIsShow {
    detail: {
      value: boolean;
    }
  }

  // 详细信息是否需要填写
  export const getIsShow = () => {
    return t.request<IEditUserInfoParams, AxiosResponse<IGetIsShow>>({
      url: "/api/user_api/getIsShow",
      method: "post",
    });
  };
  
