import { ReactComponent as AvatarSrc } from "@/assets/avatar.svg";
import recordLogoSrc from "@/assets/recordLogo.svg";
import { getLoginLocalStorage } from "@/util/util";

function App() {
  const { userPhone = "", userName } = getLoginLocalStorage() || {};
  const handleMedicationRecord = () => {
    window.location.href = `/meditation?d=meditationRecord`;
  };

  const phone = `${userPhone.slice(0, 4)}****${userPhone.slice(7)}`;
  const name = userPhone === userName ? phone : userName;

  return (
    <div
      style={{
        background: "#F9F9F9",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "10px 12px",
        }}
      >
        <div>
          {/* <img
            style={{
              width: "56px",
              height: "56px",
            }}
            src={avatarSrc}
            alt=""
          /> */}
          <AvatarSrc />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "2px 0 0 12px",
            justifyContent: "center",
          }}
        >
          <p style={{ fontSize: "18px", color: "#000", fontWeight: 500 }}>
            {name}
          </p>
          {/* <p>{phone}</p> */}
        </div>
      </div>
      <div>
        <div
          style={{
            background: "#fff",
            borderRadius: "12px",
            margin: "12px",
            padding: "16px 20px",
          }}
        >
          <div
            onClick={handleMedicationRecord}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "fit-content",
            }}
          >
            <img src={recordLogoSrc} alt="" />
            <span>冥想记录</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

