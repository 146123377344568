/* Instruments */
import { counterSlice } from "./slices";
import { loginSlice } from "./slices/loginSlice";
import { microDataSlice } from "./slices/microDataSlice/microDataSlice";

export const reducer = {
  counter: counterSlice.reducer,
  login: loginSlice.reducer,
  microData: microDataSlice.reducer,
};

