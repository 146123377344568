import { PropsWithChildren } from "react";
import "./index.scss";
interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}
const Button = ({ children, onClick, ...props }: PropsWithChildren<IButton>) => {
  return (
    <button
      onClick={onClick}
      className="globalBtn w-full rounded-full leading-10 h-11 py-0.5 align-middle text-base font-bold"
      {...props}
    >
      {children}
    </button>
  );
};
export default Button;

