import { isProdEnv } from "@/util";

function App() {
  return (
    <micro-app
      name={"fiverings"}
      url={isProdEnv() ? "http://localhost:5173/" : ""}
      iframe={true}
    ></micro-app>
  );
}

export default App;

