/** @jsxRuntime classic */
/** @jsx jsxCustomEvent */
import jsxCustomEvent from "@micro-zoe/micro-app/polyfill/jsx-custom-event";
import { token, useSelector } from "@/store/redux";
import { isProdEnv, isWxBrowser, renderImageToTop, saveImg } from "@/util";
import { Toast } from "fe-react-pop";
import { useEffect } from "react";
import { asyncChildAreaWindow, getLoginLocalStorage } from "@/util/util";

function App() {
  const loginData = useSelector(token);

  const print = async () => {
    if (document.body) {
      // 这么交互的原因：https://zhuanlan.zhihu.com/p/485755669?utm_id=0
      if (isWxBrowser()) {
        await renderImageToTop(document.body);
        Toast({
          content: "长按图片保存到本地",
          delay: 1500,
        });
      } else {
        saveImg(document.body);
      }
    }
  };

  useEffect(() => {
    if (loginData) {
      asyncChildAreaWindow(getLoginLocalStorage());
    }
  }, [loginData]);

  return (
    <micro-app
      name={"h5Emo"}
      url={
        isProdEnv()
          ? "https://emo.psm.shzenon.cn"
          : "https://192.168.1.89:3001/"
      }
      data={{
        print,
        url: window.location.origin,
        token: loginData ?? "",
      }}
    ></micro-app>
  );
}

export default App;

