import { microConfig } from "@/router/router";
import { isProdEnv } from "@/util";

const loginLocalStorageKey = "__loginLocalStorageKey__";

export const setLoginLocalStorage = (persistenceData: unknown) => {
  try {
    localStorage.setItem(loginLocalStorageKey, JSON.stringify(persistenceData));
    return true;
  } catch (e) {
    return false;
  }
};

export const getLoginLocalStorage = () => {
  const loginData = localStorage.getItem(loginLocalStorageKey);
  if (loginData) {
    try {
      return JSON.parse(loginData);
    } catch (e) {
      return null;
    }
  }
  return null;
};

export const clearLoginLocalStorage = () => {
  localStorage.removeItem(loginLocalStorageKey);
};

export const isValidPhone = (phone: string) =>
  /^1[3|4|5|7|8|9][0-9]{9}$/.test(phone);

const isSafari = () =>
  navigator.userAgent.indexOf("Safari") > -1 &&
  navigator.userAgent.indexOf("Chrome") < 0;

const asyncStorage = (persistenceData: unknown, src: string) => {
  const iframe = document.createElement("iframe");
  iframe.setAttribute("style", "position: fixed;z-index:-10;");
  let asyncData = "";

  try {
    asyncData = JSON.stringify(persistenceData);
  } catch (e) {
    return false;
  }

  if (isSafari()) {
    iframe.src = `${src}?data=${asyncData}`;
  } else {
    iframe.src = src;
    window.addEventListener(
      "message",
      (e) => {
        if (e.data === "ready") {
          iframe.contentWindow?.postMessage?.(asyncData, iframe.src);
        }
      },
      false
    );
  }

  iframe.addEventListener("load", () => {
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 5000);
  });

  document.body.appendChild(iframe);
};

export const asyncChildAreaWindow = (data: unknown) => {
  microConfig
    .filter((item) => !!item.microPath)
    .forEach((item) => {
      asyncStorage(data, `${item.microPath}/iframe.html`);
    });
};

