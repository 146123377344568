import { redirect } from "react-router-dom";
const getLoginLocalStorage = () => {
  const loginData = localStorage.getItem("__loginLocalStorageKey__");
  if (loginData) {
    try {
      return JSON.parse(loginData);
    } catch (e) {
      return null;
    }
  }
  return null;
};
const isLogin = () => {
  const { userPhone = "" } = getLoginLocalStorage() || {};
  return !!userPhone;
};

export async function AuthLoader() {
  if (isLogin()) {
    return {
      isLogin: true,
    };
  }
  return redirect("/login");
}

