import html2canvas from "html2canvas";
import PopImage from "../components/PopImage";

export const isProdEnv = () => process.env.NODE_ENV === "production";

export const getSearchData = () => {
  const searchString = decodeURIComponent(window.location.search).substring(1);
  const split = searchString.match(/[a-zA-Z0-9]+=[a-zA-Z0-9]+/g);
  const searchObj: { [x: string]: string } = {};
  split?.forEach((item) => {
    const [k, v] = item.split("=");
    searchObj[k] = v;
  });
  return searchObj;
};

const getHtmlCanvas = (element: HTMLElement): Promise<HTMLCanvasElement> => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;

  return html2canvas(element, {
    allowTaint: true,
    useCORS: true,
    scale: 2,
    onclone: (ele) => {
      ele.body.style.padding = "0px";
      ele.body.style.margin = "0px";
    },
    ignoreElements: (element) => {
      return (
        element.classList?.contains("print-ignore") ||
        ["TITLE", "MICRO-APP-HEAD", "SCRIPT", "HEAD", "NOSCRIPT"].includes(
          element.tagName
        )
      );
    },
  });
};

export const domToImage = (element: HTMLElement): Promise<string> => {
  return getHtmlCanvas(element).then((canvas: HTMLCanvasElement) => {
    return canvas.toDataURL("image/png");
  });
};

export const renderImageToTop = async (element: HTMLElement) => {
  const imgUrl = await domToImage(element);
  PopImage({
    src: imgUrl,
  });
};

export const domToBlob = (element: HTMLElement): Promise<Blob | null> => {
  return getHtmlCanvas(element).then((canvas) => {
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      });
    });
  });
};
const saveBlob = (blob: Blob, fileName: string) => {
  const a = document.createElement("a");
  a.style.display = "none";
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
};
export const saveImg = async (element: HTMLElement): Promise<void> => {
  // 调用函数，取到截图的blob数据，对图片进行处理（保存本地、展示等）
  const blob = await domToBlob(element);

  // 下载图片到手机
  if (blob) {
    saveBlob(blob, "报告");
  }
};

// 判断是否H5微信环境，true为微信浏览器
export const isWxBrowser = () =>
  /MicroMessenger/i.test(window.navigator.userAgent);
