/* Core */
import { createSlice } from "@reduxjs/toolkit";

const initialState: microDataSliceState = {
  currentMicro: "",
};

export const microDataSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setMicro: (state, action) => {
      if (action.payload) {
        state.currentMicro = action.payload;
      }
    },
  },
});

/* Types */
export interface microDataSliceState {
  currentMicro: string;
}

