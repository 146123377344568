import { isProdEnv } from "@/util";

function Meditation() {
  const defaultPathMatch = window.location.search.match(/d=([\w+/]+)/);
  const searchStr = window.location.search?.match(/music=[\w+/]+/)?.[0] ?? "";

  const defaultUrl = `${defaultPathMatch?.[1] || "/"}${
    searchStr ? "?" : ""
  }${searchStr}`;
  return (
    <micro-app
      name={"meditation"}
      default-page={defaultUrl || "/"}
      url={
        isProdEnv() ? "https://med.psm.shzenon.cn" : "http://192.168.1.89:5174/"
      }
      iframe={true}
    ></micro-app>
  );
}

export default Meditation;

