import closeSrc from "@/assets/close.svg";
import { Pop as PopFunction } from "fe-react-pop";
import { MouseEventHandler } from "react";
interface IPop {
  src: string;
}

const ImageChild = ({
  destroy,
  src,
}: {
  destroy?: MouseEventHandler<HTMLElement>;
  src: string;
}) => {
  return (
    <div
      className="text-white px-6 py-3 h-screen relative"
      style={{
        background: "rgba(0,0,0,0.3)",
      }}
    >
      <div
        className="overflow-auto"
        style={{
          maxHeight: "80vh",
        }}
      >
        <img src={src} alt="" />
      </div>
      <div className="absolute bottom-11 flex justify-center left-0 right-0 m-auto p-2">
        <img
          onClick={destroy}
          className="w-9  aspect-auto"
          src={closeSrc}
          alt=""
        />
      </div>
    </div>
  );
};

const PopImage = ({ src }: IPop) => {
  return PopFunction({
    customDomId: "PopImage",
    children: <ImageChild src={src} />,
  });
};

export default PopImage;

