import { Agreement as popAgreement } from "fe-react-pop";

const Agreement = () => {
  return (
    <div className="px-3">
      <p className=" mb-2 leading-7">
        <span className=" text-sm">一、特别提示</span>
      </p>
      <p className=" mb-2 indent-6 leading-7">
        <span className=" text-sm">
          在此特别提醒您（用户）在注册成为Emosense心探用户之前，请认真阅读本《Emosense心探用户服务协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。您同意并点击确认本协议条款且完成注册程序后，才能成为Emosense心探的正式注册用户，并享受Emosense心探的各类服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。若您不同意本协议，或对本协议中的条款存在任何疑问，请您立即停止Emosense心探用户注册程序，并可以选择不使用本网站服务。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          本协议约定Emosense心探与用户之间关于“Emosense心探”服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人、单位。本协议可由Emosense心探随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本APP中查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用Emosense心探提供的服务，用户继续使用Emosense心探提供的服务将被视为接受修改后的协议。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">二、账号注册</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          1、用户在使用本服务前需要注册一个“Emosense心探”账号。“Emosense心探”账号应当使用手机号码绑定注册，请用户使用尚未与“Emosense心探”账号绑定的手机号码，以及未被Emosense心探根据本协议封禁的手机号码注册“Emosense心探”账号。Emosense心探可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          2、如果注册申请者有被Emosense心探封禁的先例或涉嫌虚假注册及滥用他人名义注册，及其他不能得到许可的理由，Emosense心探将拒绝其注册申请。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          3、鉴于“Emosense心探”账号的绑定注册方式,您同意Emosense心探在注册时将允许您的手机号码及手机设备识别码等信息用于注册。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          4、在用户注册及使用本服务时,Emosense心探需要搜集能识别用户身份的个人信息以便Emosense心探可以在必要时联系用户,或为用户提供更好的使用体验。Emosense心探搜集的信息包括但不限于用户的姓名、地址;Emosense心探同意对这些信息的使用将受限于第三条用户个人隐私信息保护的约束。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">三、账户安全</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          1、用户一旦注册成功,成为Emosense心探的用户,将得到一个用户名和密码,并有权利使用自己的用户名及密码随时进行登陆。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          2、用户对用户名和密码的安全负全部责任,同时对以其用户名进行的所有活动和事件负全责。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          3、用户不得以任何形式擅自转让或授权他人使用自己的Emosense心探用户名。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          4、用户对密码加以妥善保管,切勿将密码告知他人,因密码保管不善而造成的所有损失由用户自行承担。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          5、如果用户泄漏了密码,有可能导致不利的法律后果,因此不管任何原因导致用户的密码安全受到威胁,应该立即和Emosense心探客服人员取得联系,否则后果自负。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">四、用户声明与保证</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          1、用户承诺其为具有完全民事行为能力的民事主体,且具有达成交易履行其义务的能力。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          2、用户有义务在注册时提供自己的真实资料,并保证诸如手机号码、姓名、所在地区等内容的有效性及安全性,保证Emosense心探工作人员可以通过上述联系方式与用户取得联系。同时,用户也有义务在相关资料实际变更时及时更新有关注册资料。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          3、用户通过使用Emosense心探的过程中所制作、上载、复制、发布、传播的任何內容,包括但不限于账号头像、名称、用户说明等注册信息及认证资料,或文字、语音、图片、视频、图文等发送、回复和相关链接页面,以及其他使用账号或本服务所产生的内容,不得违反国家相关法律制度,包含但不限于如下原则:
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">(1)违反宪法所确定的基本原则的；</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          (2)危害国家安全,泄露国家秘密,颠覆国家政权,破坏国家统一的；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">(3)损害国家荣誉和利益的；</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          (4)煽动民族仇恨、民族歧视,破坏民族团结的；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          (5)破坏国家宗教政策,宣扬邪教和封建迷信的；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          (6)散布谣言,扰乱社公秩序,破坏社会稳定的；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          (7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          (8)侮辱或者诽谤他人,侵害他人合法权益的；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          (9)含有法律、行政法规禁止的其他内容的。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          4、用户不得利用“Emosense心探”账号或本服务制作、上载、复制、发布、传播下干扰“Emosense心探”的正常运营,以及侵犯其他用户或第三方合法权益的内容：
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">(1)含有任何性或性暗示的；</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">(2)含有辱骂、恐吓、威胁内容的；</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          (3)含有骚扰、垃圾广告、恶意信息、诱骗信息的；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">(4)涉及他人隐私、个人信息或资料的；</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          (5)侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          (6)含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">五、服务内容</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          1、小科秒检是由Emosense心探科技有限公司提供的非接触式生理指标检测平台,用户通过Emosense心探APP检测生理指标，并提供健康风险报告。具体服务内容由Emosense心探根据实际情况提供,包括但不限于:
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">(1)在线搜索、发布文章信息服务；</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">(2)其他第三方服务；</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          (3)生理指标检测，提供健康风险报告服务；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">(4)健康趋势查看服务；</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">(5)报告分享下载服务；</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          2、Emosense心探有权随时审核或删除用户发布/传播的涉嫌违法或违反社会主义精神文明,或者被Emosense心探认为不妥当的内容(包括但不限于文字、语音、图片、视频图文等)。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          3、所有发给用户的通告及其他消息都可通过APP或者用户所提供的联系方式发送。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">4、用户购买会员，通过线上交易的方式</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">六、服务的终止</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          1、在下列情况下,Emosense心探有权终止向用户提供服务；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          （1）在用户违反本服务协议相关规定时，Emosense心探有权终止向该用户提供服务；如该用户再一次直接或间接或以他人名义注册为用户的，一经发现，Emosense心探有权直接单方面终止向该用户提供服务；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          （2）如Emosense心探通过用户提供的信息与用户联系时，发现用户在注册时填写的联系方式已不存在或无法接通，Emosense心探以其它联系方式通知用户更改，而用户在三个工作日内仍未能提供新的联系方式，Emosense心探有权终止向该用户提供服务；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          （3）用户不得通过程序或人工方式进行刷量或作弊，若发现用户有作弊行为，Emosense心探将立即终止服务，并有权扣留账户内剩余次数；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          （4）一旦Emosense心探发现用户提供的数据或信息中含有虚假内容，Emosense心探有权随时终止向该用户提供服务；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          （5）本服务条款终止或更新时，用户明示不愿接受新的服务条款；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          （6）以及其他Emosense心探认为需要终止服务的情况。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          2、服务终止后，Emosense心探没有义务为用户保留原账号中或与之相关的任何信息，或转发任何未曾阋读或发送的信息给用户或第三方。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          3、用户理解并同意，即便在本协议终止及用户的服务被终止后，Emosense心探仍有权：
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">（1）继续续保存您的用户信息；</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          （2）继续向用户主张在其使用百姓网平台服务期间因违反法律法规、本协议及平台规则而应承担的责任。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">七、服务的变更、中断</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          1、鉴于网络服务的特殊性，用户需同意Emosense心探会变更、中断部分或全部的网络服务，并删除（不再保存）用户在使用过程中提交的任何资料，而无需通知用户，也无需对任何用户或任何第三方承担任何责任。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          2、Emosense心探需要定期或不定期地对提供网络服务的平台进行检测或者更新，如因此类情况而造成网络服务在合理时间内的中断，Emosense心探无需为此承担任何责任。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">八、服务条款修改</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          1、Emosense心探有权随时修改本服务条款的任何内容，一旦本服务条款的任何内容发生变动，Emosense心探将会通过适当方式向用户提示修改内容。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          2、如果不同意Emosense心探对本服务条款所做的修改，用户有权停止使用网络服务。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          3、如果用户继续使用网络服务，则视为用户接受Emosense心探对本服务条款所做的修改。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">九、免责与赔偿声明</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          1、若Emosense心探已经明示其服务提供方式发生变更并提醒用户应当注意事项，用户未按要求操作所产生的一切后果由用户自行承担。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          2、用户明确同意其使用Emosense心探所存在的风险将完全由其自己承担，因其使用Emosense心探而产生的一切后果也由其自己承担。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          3、用户同意保障和维护Emosense心探及其他用户的利益，由于用户在使用Emosense心探有违法、不真实、不正当、侵犯第三方合法权益的行为，或用户违反本协议项下的任何条款而给Emosense心探及任何其他第三方造成损失，用户同意承担由此造成的损害赔偿责任。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          十、隐私声明，包括但不限于以下内容（详见于隐私政策）
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">1、适用范围：</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          （1）在用户注册Emosense心探账户时，根据要求提供的个人注册信息；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          （2）在用户使用Emosense心探，或访问其相关网页时，Emosense心探自动接收并记录的用户浏览器上的服务器数值，包括但不限于IP地址等数据及用户要求取用的网页记录。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" font-size:10.5pt">&nbsp;</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">2、信息使用：</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          （1）Emosense心探不会向任何人出售或出借用户的个人信息，除非事先得到用户的许可；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          （2）Emosense心探亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播用户的个人信息。任何用户如从事上述活动，一经发现，Emosense心探有权立即终止与该用户的服务协议，查封其账号；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          （3）为达到服务用户的目的，Emosense心探可能通过使用用户的个人信息，向用户提供服务，包括但不限于向用户发出产品和服务信息，或者与Emosense心探合作伙伴共享信息以便他们向用户发送有关其产品和服务的信息。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          3、信息披露：用户的个人信息将在下述情況下部分或全部被披露：
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">（1）经用户同意，向第三方披露；</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          （2）根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          （3）如果用户出现违反中国有关法律或者网站政策的情况，需要向第三方披露；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          （4）为提供用户所要求的产品和服务，而必须和第三方分享用户的个人信息；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          （5）其它Emosense心探根据法律或者网站政策认为合适的披露；
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          （6）用户使用Emosense心探时提供的银行账户信息，Emosense心探将严格履行关保密约定。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">十一、其他</span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          1、Emosense心探郑重提醒用户注意本协议中免除Emosense心探来责任
        </span>
        <span className=" text-sm">
          和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和Emosense心探之间发生任何纠纷或争议，首先应友好协解决，协商不成的，用户同意将纠纷或争议提交Emosense心探住所地有管辖权的人民法院管辖。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">
          4、本协议最终解释权归Emosense心探有限公司所有，并且保留一切解释和修改的权力。
        </span>
      </p>
      <p className=" mb-2 leading-7">
        <span className=" text-sm">5、本协议自2023年12月31日起适用。</span>
      </p>
      <p className=" mb-2 text-justify leading-7">
        <span className=" text-sm">&nbsp;</span>
      </p>
    </div>
  );
};

const Privacy = () => {
  return (
    <div className="px-3">
      <p className=" indent-6 text-sm">
        <span>
          上海迎智正能健康科技有限公司（以下简称“本应用”）尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，本应用会按照本隐私权政策的规定使用和披露您的个人信息。但本应用将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本应用不会将这些信息对外披露或向第三方提供。本应用会不时更新本隐私权政策。
          您在同意本应用服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本应用服务使用协议不可分割的一部分。
        </span>
      </p>
      <h4 className="mt-1 mb-1 text-sm leading-6 font-bold">
        <span>1. 适用范围</span>
      </h4>
      <p className=" text-sm">
        <span>
          1、本应用会获取您的OAID，比如在使用推送或者广告等功能的时候。
        </span>
      </p>
      <p className=" text-sm">
        <span>
          2、当您使用我们的非接触式检测服务时，我们会调用设备的摄像头以获取您的面部数据，您的图像数据我们将承诺不会进行存储。
        </span>
      </p>
      <p className=" text-sm">
        <span>
          3、为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入了自己开发的非接触式健康检测SDK。
        </span>
      </p>
      <p className=" text-sm">
        <span>
          (a) 在您注册本应用账号时，您根据本应用要求提供的个人注册信息；
        </span>
      </p>
      <p className=" text-sm">
        <span>
          (b)
          在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、通过硬件采集到的个人生理指标和健康风险、软硬件特征信息及您需求的网页记录等数据；
        </span>
      </p>
      <p className=" text-sm">
        <span>(c) 本应用通过合法途径从商业伙伴处取得的用户个人数据。</span>
      </p>
      <p className=" text-sm">
        <span>
          (d)使用本应用时提交以下额外个人信息，包括：昵称、年龄、性别、身高、体重、是否抽烟、是否有高血压、是否正在服用治疗血压的药物、是否有糖尿病（1型、2型、否）
        </span>
      </p>
      <p className=" text-sm">
        <span>您了解并同意，以下信息不适用本隐私权政策：</span>
      </p>
      <p className=" text-sm">
        <span>(a) 您在使用本应用平台提供的搜索服务时输入的关键字信息；</span>
      </p>
      <p className=" text-sm">
        <span>
          (b)
          本应用收集到的您在本应用发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；
        </span>
      </p>
      <p className=" text-sm">
        <span>
          (c) 违反法律规定或违反本应用规则行为及本应用已对您采取的措施。
        </span>
      </p>
      <h4 className="mt-1 mb-1 text-sm leading-6 font-bold">
        <span>2. 信息使用</span>
      </h4>
      <p className=" text-sm">
        <span>
          (a)本应用不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本应用（含本应用关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。
        </span>
      </p>
      <p className=" text-sm">
        <span>
          (b)
          本应用亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本应用平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。
        </span>
      </p>
      <p className=" text-sm">
        <span>
          (c)
          为服务用户的目的，本应用可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与本应用合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。
        </span>
      </p>
      <h4 className="mt-1 mb-1 text-sm leading-6 font-bold">
        <span>3. 信息披露</span>
      </h4>
      <p className=" text-sm">
        <span>
          在如下情况下，本应用将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
        </span>
      </p>
      <p className=" text-sm">
        <span>(a) 经您事先同意，向第三方披露；</span>
      </p>
      <p className=" text-sm">
        <span>
          (b)为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；
        </span>
      </p>
      <p className=" text-sm">
        <span>
          (c)
          根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
        </span>
      </p>
      <p className=" text-sm">
        <span>
          (d)
          如您出现违反中国有关法律、法规或者本应用服务协议或相关规则的情况，需要向第三方披露；
        </span>
      </p>
      <p className=" text-sm">
        <span>
          (e)
          如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；
        </span>
      </p>
      <p className=" text-sm">
        <span>
          (f)
          在本应用平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，本应用有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。
        </span>
      </p>
      <p className=" text-sm">
        <span>(g) 其它本应用根据法律、法规或者网站政策认为合适的披露。</span>
      </p>
      <h4 className="mt-1 mb-1 text-sm leading-6 font-bold">
        <span>4. 信息存储和交换</span>
      </h4>
      <p className=" text-sm">
        <span>
          本应用收集的有关您的信息和资料将保存在本应用及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本应用收集信息和资料所在地的境外并在境外被访问、存储和展示。
        </span>
      </p>
      <p className="mt-1 mb-1 text-sm leading-6 font-bold">
        <span>5. 我们如何使用Cookie或相关同类技术</span>
      </p>
      <p className=" text-sm">
        <span>
          (a)
          在您未拒绝接受cookies的情况下，本应用会在您的计算机上设定或取用cookies
          ，以便您能登录或使用依赖于cookies的本应用平台服务或功能。本应用使用cookies可为您提供更加周到的个性化服务，包括推广服务。
        </span>
      </p>
      <p className=" text-sm">
        <span>
          (b)
          您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的本应用网络服务或功能。
        </span>
      </p>
      <p className=" text-sm">
        <span>(c) 通过本应用所设cookies所取得的有关信息，将适用本政策。</span>
      </p>
      <h4 className="mt-1 mb-1 text-sm leading-6 font-bold">
        <span>6</span>
        <span>. 信息安全</span>
      </h4>
      <p className=" text-sm">
        <span>
          (a)
          本应用账号有安全保护功能，请妥善保管您的用户名及密码信息。本应用将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存
        </span>
        <span>在“完善的安全措施”。</span>
      </p>
      <p className=" text-sm">
        <span>
          (b)
          在使用本应用网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对。
        </span>
      </p>
      <p className=" text-sm">
        <span>
          (c)
          保证您的个人数据的安全对我们来说至关重要。当您在本公司的移动App中注册输入个人数据时，我们会利用安全套接字层技术
          (SSL) 对这些信息进行加密。
        </span>
        <span>&nbsp;</span>
      </p>
      <p className=" text-sm">
        <span>
          (d)
          在数据传输和数据保管两个阶段里，我们会通过广为接受的行业标准（如防火墙、加密和数据隐私法律要求）来保护您向我们提交的信息。
        </span>
      </p>
      <h4 className="mt-1 mb-1 text-sm leading-6 font-bold">
        <span>7</span>
        <span>.本隐私政策的更改</span>
      </h4>
      <p className=" text-sm">
        <span>
          (a)如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。
        </span>
      </p>
      <p className=" text-sm">
        <span>
          (b)本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。
        </span>
      </p>
      <p className=" text-sm">
        <span>
          为防止向第三方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本应用用户名及密码发生泄露，请您立即联络本应用客服，以便本应用采取相应措施。
        </span>
      </p>
      <h4 className="mt-1 mb-1 text-sm leading-6 font-bold">
        <span>8</span>
        <span>.遇到问题</span>
      </h4>
      <p className=" text-sm">
        <span>如果您对本政策有任何问题或投诉，请到官网与我们联系。</span>
      </p>
      <p className=" font-size:10.5pt">
        <span className="font-family:Helvetica; font-weight:bold; background-color:#ffffff">
          &nbsp;
        </span>
      </p>
      <p className=" text-justify widows:0; orphans:0; font-size:10.5pt">
        <span>&nbsp;</span>
      </p>
    </div>
  );
};

const PopAgreement = () => {
  return popAgreement({
    content: <Agreement />,
    title: "用户使用服务协议",
  });
};

export const PopPrivacy = () => {
  return popAgreement({
    content: <Privacy />,
    title: "隐私政策",
  });
};

export default PopAgreement;
