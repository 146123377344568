import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";

import { RouterProvider } from "react-router-dom";
import { Providers } from "./store/providers.tsx";
import "./index.scss";
import router, { microConfig } from "./router/router.tsx";
import microApp from "@micro-zoe/micro-app";
import { useDispatch } from "./store/redux/store";
import { microDataSlice } from "./store/redux/index.ts";

microApp.preFetch(
  microConfig
    .filter((item) => item.microPath)
    .map((item) => ({
      name: item.name,
      url: item.path,
      iframe: item.isVite,
    }))
);

microApp.addDataListener(
  "h5Emo",
  ({ path = "", app, type }: { path: string; app: string; type: string }) => {
    switch (type) {
      case "switchApp": {
        console.log(`主应用跳转到${app}`);
        window.location.href = `/${app}?d=${path}`;
      }
    }
    return true;
  }
);
microApp.addDataListener(
  "meditation",
  ({ path = "", app, type }: { path: string; app: string; type: string }) => {
    switch (type) {
      case "switchApp": {
        console.log(`主应用跳转到${app}`);
        window.location.href = `${window.location.origin}/${app}?d=${path}`;
      }
    }
    return true;
  }
);
microApp.start({
  // fetch(url, option) {
  //   return window.fetch(url, option).then((res) => {
  //     return res.text().then((text) => {
  //       console.log(text, "Text");
  //       return text;
  //     });
  //   });
  // },
});

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let currentApp = microApp.getActiveApps({
      excludeHiddenApp: true,
      excludePreRender: true,
    });

    if (!currentApp?.length) {
      const { pathname } = window.location;
      currentApp = pathname.replace(/^\//, "")?.split("/");
    }

    dispatch(microDataSlice.actions.setMicro(currentApp[0] || ""));
  }, [dispatch]);

  return <RouterProvider router={router} />;
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>
);

