import { microConfig } from "@/router/router";
import { currentMicro, microDataSlice, useSelector } from "@/store/redux";
import { FC, PropsWithChildren, useEffect } from "react";
import { useDispatch } from "../store/redux/store";
import microApp from "@micro-zoe/micro-app";
import { useLocation } from "react-router-dom";
import { getIsShow } from "@/service/models/apis";

const tabHeight = 50;

const BasicLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const micro = useSelector(currentMicro);
  const { search, pathname } = useLocation();
  const dispatch = useDispatch();
  const handleClick = (path: string, name: string) => {
    window.location.href = `/${path}`;
    dispatch(microDataSlice.actions.setMicro(name));
  };
  const searchStart = pathname?.replace(/^\//, "?");
  const encodeResult = microApp.router.decode(search);
  const hasTabParam = /hasTab=1/.test(encodeResult);
  let isHomePage = false;
  try {
    isHomePage = new RegExp(`^\\${searchStart}=/$`).test(encodeResult);
    isHomePage = isHomePage || /\\?meditation=\/h5Emo/i.test(encodeResult);
  } catch (e) {}
  const hasTab = isHomePage || hasTabParam || pathname === "/my";

  useEffect(() => {
    if (!window.location.pathname.includes('personInfo')) {
      getIsShow().then(res => {
        if(res?.data?.detail?.value) {
          window.location.href = `/personInfo`;
        }
      })

    }
  }, [])

  return (
    <div className="flex flex-col h-full">
      <div
        className="flex-auto overflow-auto basicLayoutWrap"
        style={{
          height: `calc(var(--vh) * 100 - ${hasTab ? 50 : 0}px)`,
        }}
      >
        {children}
      </div>
      {hasTab && (
        <div
          className="print-ignore"
          style={{
            zIndex: 1,
            boxSizing: "border-box",
            fontSize: "12px",
            height: `${tabHeight}px`,
            display: "flex",
            padding: "6px 0 6px 0",
            boxShadow: "0 -1px 1px #E3E3E3",
          }}
        >
          {microConfig.map(({ name, title, path, icon: Icon }) => (
            <div
              key={name}
              style={{
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() => handleClick(path, name)}
            >
              {Icon && (
                <Icon
                  style={{
                    color: micro === name ? "#07C160" : "#B3B3B3",
                  }}
                />
              )}
              <div>{title}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BasicLayout;
