import { Header } from "fe-react-pop";
import { DatePicker, DatePickerRef, Form, Input, Radio, Space } from 'antd-mobile';
import { RefObject } from "react";
import dayjs from "dayjs";
import { editUserInfo, IEditUserInfoParams } from "@/service/models/apis";
import Button from "@/components/Button";
import { CheckCircleFill } from "antd-mobile-icons";
import "./index.scss";
const rules = [{ required: true, message: '必填' }];

const checkIcon = (checked: boolean) => checked ? <CheckCircleFill  color='#00C553'  /> : <div className="adm-radio-icon"></div>
function App() {
  const onFinish = (values: IEditUserInfoParams) => {
    editUserInfo(values).then(res => {
      window.location.href = `/h5Emo`;
    })
  }

  return (
    <div className="h-full" style={{ background: "#F8F8F8" }}>
      <Header style={{ background: "#fff" }} onBack={() => window.history.go(-1)}>完善基本信息</Header>
      <Form layout='horizontal' mode='card' onFinish={onFinish}
        className="personInfoForm"
        footer={
          <Button type='submit'>
            保存信息
          </Button>
        }
      >
        <Form.Header><div className="font-bold text-black pl-4">预约信息</div></Form.Header>
        <Form.Item label='姓名' name="userName" rules={rules}>
          <Input placeholder='请输入' />
        </Form.Item>
        <Form.Item label='性别' name="userSex" rules={rules}>
          <Radio.Group>
            <Space>
              <Radio value='1' icon={checkIcon}>男</Radio>
              <Radio value='2' icon={checkIcon}>女</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item label='出生日期' name="userBirth" rules={rules}
          trigger='onConfirm'
          onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open()
          }}
        >
          <DatePicker min={dayjs().add(-80, 'year').toDate()} max={new Date()}>
            {value =>
              value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'
            }
          </DatePicker>
        </Form.Item>
        <Form.Item label='工作地点' name="placeWork" rules={rules}>
          <Input placeholder='请输入' />
        </Form.Item>
        <Form.Header />
      </Form>
    </div>
  )
}

export default App;

