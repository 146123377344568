import { useState } from "react";
import Button from "@/components/Button";
import { Footer } from "@/components/Footer";
import VerityPop from "./VerityPop";
import { useDispatch } from "@/store/redux";
import { loginSendSmsAsync } from "@/store/redux/slices/loginSlice/thunks";
import PopAgreement, { PopPrivacy } from "./Agreement";
import "./index.scss";

function Login() {
  const [visible, setVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [agree, setAgree] = useState(false);
  const dispatch = useDispatch();

  const handleSendMsg = async () => {
    const res = await dispatch(
      loginSendSmsAsync({
        userPhone: phoneNumber,
        acceptNotes: agree ? "Y" : "N",
      })
    );
    if (res.payload) {
      setVisible(true);
    }
  };

  const handlePhoneChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleChangeAgree: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setAgree(e.target.checked);
  };

  return (
    <div className="linerBackgroundWithLogo login container bg-fixed bg-cover bg-top flex flex-col items-center">
      <div className="aspect-square w-44 mt-14 mb-3" />
      <p className="italic font-bold text-xl font-sans mb-1">您好，欢迎登录</p>
      <p className="italic font-bold text-3xl mb-7 emoColor">EMOSENSE 心探</p>
      <div className="w-full px-6 leading-10 mb-4">
        <input
          className="w-full border-b outline-none text-base rounded-none  bg-transparent"
          value={phoneNumber}
          onChange={handlePhoneChange}
          placeholder="手机号码"
          type="tel"
          pattern="tel"
        />
      </div>
      <label className="w-full px-6 leading-10 flex items-center justify-center bg-transparent">
        <input
          checked={agree}
          onChange={handleChangeAgree}
          type="checkbox"
          className="mr-2 rounded-none text-lg bg-transparent"
        />
        <div>
          我已阅读并同意
          <span onClick={PopAgreement} className="agreementColor">
            《用户协议》
          </span>
          和
          <span onClick={PopPrivacy} className="agreementColor">
            《隐私政策》
          </span>
        </div>
      </label>

      <div className="w-full px-12 mt-6">
        <Button onClick={handleSendMsg}>获取验证码</Button>
      </div>
      <Footer />
      <VerityPop
        code={phoneNumber}
        visible={visible}
        resend={handleSendMsg}
        hideVerity={() => setVisible(false)}
      />
    </div>
  );
}

export default Login;

