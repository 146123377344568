export const Footer = () => {
  return (
    <div
      style={{
        pointerEvents: "none",
      }}
      className="flex flex-col-reverse flex-auto pb-4 text-center text-gray-400"
    >
      上海迎智正能健康科技有限公司
    </div>
  );
};
