import { PropsWithChildren } from "react";
import ReactDOM from "react-dom";
interface IPop {
  visible: boolean;
}
const Pop = ({ children, visible }: PropsWithChildren<IPop>) => {
  return visible
    ? ReactDOM.createPortal(
        <div className="fixed top-0 bottom-0 left-0 right-0">{children}</div>,
        document.body,
      )
    : null;
};
export default Pop;
