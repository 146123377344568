import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { VerityCodeV2, Header } from "fe-react-pop";
import { loginAsync } from "@/store/redux/slices/loginSlice/thunks";
import { useDispatch } from "@/store/redux";
import Pop from "@/components/Pop";

interface IVerityPop {
  visible: boolean;
  code: string;
  resend: () => void;
  hideVerity: () => void;
}

const ResendTimeInterval = 60000;
function VerityPop({ visible, code, resend, hideVerity }: IVerityPop) {
  const dispatch = useDispatch();
  const resendTimer = useRef<NodeJS.Timeout>();
  const location = useLocation();
  const [countdown, setCountdown] = useState(ResendTimeInterval);
  const handleVerity = async (bit: string) => {
    const [_, channel] = location.search?.match?.(/c=([\dA-Za-z]+)/) || [];
    const res = await dispatch(
      loginAsync({
        userPhone: code,
        acceptNotes: "Y",
        code: bit,
        orgCode: channel
      })
    );
    
    if (res.payload) {
      // @ts-ignore
      if (res.payload?.data?.detail?.isShow === 'Y') {
        window.location.href = `/personInfo`;
      } else {
        window.location.href = `/h5Emo`;
      }
    }
  };

  const handleResend = () => {
    if (countdown > 0) {
      return;
    }
    startCount();
    resend();
  };

  const startCount = () => {
    setCountdown(ResendTimeInterval);
    resendTimer.current = setInterval(() => {
      setCountdown((v) => v - 1000);
    }, 1000);
  };

  useEffect(() => {
    if (visible) {
      startCount();
    } else {
      clearInterval(resendTimer.current);
    }
  }, [visible]);

  useEffect(() => {
    if (countdown <= 0) {
      clearInterval(resendTimer.current);
    }
  }, [countdown]);

  return (
    <Pop visible={visible}>
      <div className="linerBackground px-6 h-screen">
        <Header onBack={hideVerity} />
        <p className="text-2xl font-bold mt-6">输入短信验证码</p>
        <p className="text-sm text-neutral-500 mt-2">
          验证码已发送至 +86 {code}
        </p>
        <div className="mt-16">
          <VerityCodeV2
            onFinish={handleVerity}
            inputProps={{
              pattern: "[0-9]*",
              style: { background: "transparent" },
            }}
          />
        </div>
        <div
          onClick={handleResend}
          className={`text-base mt-6 ${countdown <= 0 ? "" : "text-gray-400"}`}
        >
          重新获取{countdown <= 0 ? "" : `（${countdown / 1000}s）`}
        </div>
        <p className="text-sm text-gray-400 mt-6">
          未注册的手机号验证后自动创建账号
        </p>
      </div>
    </Pop>
  );
}

export default VerityPop;

