import { createBrowserRouter, redirect } from "react-router-dom";
import Login from "@/pages/Login/index.tsx";
import BasicLayout from "@/layout/BasicLayout.tsx";
import H5emo from "@/pages/h5emo";
import Meditation from "@/pages/meditation";
import FiveRings from "@/pages/fiveRings";
import MyPage from "@/pages/myPage";
import PersonInfo from "@/pages/personInfo";

import { ReactComponent as EmoSvgCom } from "@/assets/emo.svg";
import { ReactComponent as meditationSvg } from "@/assets/meditation.svg";
import { ReactComponent as mySvg } from "@/assets/my.svg";
import { CSSProperties } from "react";
import { isProdEnv } from "@/util";
import { AuthLoader } from "./auth";

export const microConfig: {
  index?: boolean;
  path: string;
  name: string;
  icon?: ({ style }: { style: CSSProperties }) => React.ReactElement;
  microPath: string;
  isVite?: boolean;
  title: string;
}[] = [
  {
    index: true,
    title: "心探",
    path: "h5Emo",
    name: "h5Emo",
    microPath: isProdEnv()
      ? "https://emo.psm.shzenon.cn"
      : "https://192.168.1.89:3001",
    icon: EmoSvgCom,
  },
  {
    isVite: true,
    title: "冥想",
    path: "meditation",
    name: "meditation",
    microPath: isProdEnv()
      ? "https://med.psm.shzenon.cn"
      : "http://192.168.1.89:5174",
    icon: meditationSvg,
  },
  {
    title: "我的",
    path: "my",
    name: "my",
    icon: mySvg,
    microPath: "",
  },
];
const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    loader: () => redirect("/h5Emo"),
  },
  {
    children: [
      {
        path: "h5Emo",
        element: (
          <BasicLayout>
            <H5emo />
          </BasicLayout>
        ),
      },
      {
        path: "personInfo",
        element: (
          <BasicLayout>
            <PersonInfo />
          </BasicLayout>
        ),
      },
      {
        path: "meditation",
        element: (
          <BasicLayout>
            <Meditation />
          </BasicLayout>
        ),
      },
      {
        path: "my",
        loader: AuthLoader,
        element: (
          <BasicLayout>
            <MyPage />
          </BasicLayout>
        ),
      },
      {
        path: "fiverings",
        element: (
          <BasicLayout>
            <FiveRings />
          </BasicLayout>
        ),
      },
    ],
  },
]);

export default router;

